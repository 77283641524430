<template>
    <section class="promotions container-fluid d-flex flex-column h-100 p-3">
        <div class="row h-100 m-0">
            <div class="col-lg-7 col-xl-8 h-100 overflow-auto">
                <div class="position-sticky title d-flex align-items-center mb-3">
                    <div class="d-flex d-xl-none align-items-center">
                        <button-toggle-side-bar></button-toggle-side-bar>
                    </div>
                    <h1 class="display-4 font-weight-bolder col pl-xl-0 mb-0">
                        Promotions
                    </h1>
                    <div class="d-flex align-items-center">
                        <button class="btn btn-light border text-nowrap shadow-sm"
                                v-on:click="isAddPromotionModalOpened = true">
                            ✨ New Promotion
                        </button>
                    </div>
                </div>
                <div class="position-sticky promotion-list-heading d-flex justify-content-between align-items-center mb-0 pb-2">
                    <div>
                        <h3>
                            <span class="badge badge-primary mr-1">
                                {{ promotedPromotions.length }}
                            </span>
                            <span class="">
                                Promoting
                            </span>
                        </h3>
                    </div>
                    <b-form-checkbox switch
                                     v-model="allowReorderPromotedPromotions"
                                     v-on:change="dragEnded">
                        Reorder
                    </b-form-checkbox>
                </div>
                <div v-if="promotedPromotions.length === 0">
                    <h5 class="text-center font-weight-normal mb-4">
                        No promoted promotion
                    </h5>
                </div>
                <draggable class="row"
                           tag="div"
                           v-else
                           v-bind:disabled="!allowReorderPromotedPromotions"
                           v-model="promotedPromotions"
                           v-on:start="drag=true"
                           v-on:end="drag=false">
                    <promotion-card class="col-lg-6 col-xl-4 mb-4"
                                    v-for="promotion in promotedPromotions"
                                    v-bind:key="promotion._id"
                                    v-bind:dragging="allowReorderPromotedPromotions"
                                    v-bind:promotion="promotion">
                    </promotion-card>
                </draggable>

                <div class="position-sticky promotion-list-heading d-flex justify-content-between align-items-center mb-0 pb-3">
                    <div>
                        <h3>
                            <span class="badge badge-success mr-1">
                                {{ activePromotions.length }}
                            </span>
                            <span class="">
                                Active
                            </span>
                        </h3>
                    </div>
                    <b-form-checkbox switch
                                     v-model="allowReorderActivePromotions"
                                     v-on:change="dragEnded">
                        Reorder
                    </b-form-checkbox>
                </div>
                <div v-if="activePromotions.length === 0">
                    <h5 class="text-center font-weight-normal mb-4">
                        No active nonpromoted promotion
                    </h5>
                </div>
                <draggable class="row"
                           tag="div"
                           v-else
                           v-bind:disabled="!allowReorderActivePromotions"
                           v-model="activePromotions"
                           v-on:start="drag=true"
                           v-on:end="drag=false">
                    <promotion-card class="col-lg-6 col-xl-4 mb-4"
                                    v-for="promotion in activePromotions"
                                    v-bind:key="promotion._id"
                                    v-bind:dragging="allowReorderActivePromotions"
                                    v-bind:promotion="promotion">
                    </promotion-card>
                </draggable>

                <div class="position-sticky promotion-list-heading d-flex justify-content-between align-items-center mb-0 pb-3">
                    <div>
                        <h3>
                            <span class="badge badge-secondary mr-1">
                                  {{ inactivePromotions.length }}
                            </span>
                            <span class="">
                                Inactive
                            </span>
                        </h3>
                    </div>
                    <b-form-checkbox switch
                                     v-model="allowReorderInactivePromotions"
                                     v-on:change="dragEnded">
                        Reorder
                    </b-form-checkbox>
                </div>
                <div v-if="inactivePromotions.length === 0">
                    <h5 class="text-center font-weight-normal mb-4">
                        No inactive promotion
                    </h5>
                </div>
                <draggable class="row"
                           tag="div"
                           v-else
                           v-bind:disabled="!allowReorderInactivePromotions"
                           v-model="inactivePromotions"
                           v-on:start="drag=true"
                           v-on:end="drag=false">
                    <promotion-card class="col-lg-6 col-xl-4 mb-4"
                                    v-for="promotion in inactivePromotions"
                                    v-bind:key="promotion._id"
                                    v-bind:dragging="allowReorderInactivePromotions"
                                    v-bind:promotion="promotion">
                    </promotion-card>
                </draggable>
            </div>
            <div class="col-lg-5 col-xl-4 mx-3 mx-lg-0 p-lg-0 h-100">
                <router-view></router-view>
            </div>
        </div>

        <modal-promotion v-model="isAddPromotionModalOpened"></modal-promotion>
    </section>
</template>

<script>
import { BFormCheckbox, } from "bootstrap-vue";
import draggable from "vuedraggable";
export default {
    name: "Promotions",
    metaInfo () {
        return {
            "title": "Promotions",
        };
    },
    components: {
        draggable,
        BFormCheckbox,
        ButtonToggleSideBar: () => import("@/components/layout/ButtonToggleSideBar"),
        PromotionCard: () => import("../components/Promotions/PromotionCard"),
        ModalPromotion: () => import("../components/Promotions/ModalPromotion"),
    },
    data () {
        return {
            isAddPromotionModalOpened: false,

            allowReorderPromotedPromotions: false,
            allowReorderActivePromotions: false,
            allowReorderInactivePromotions: false,

            promotedPromotions: [],
            activePromotions: [],
            inactivePromotions: [],
        };
    },
    computed: {
        promotions () {
            return this.$store.getters["promotion/items"];
        },
    },
    methods: {
        async dragEnded (evt) {
            if (!evt) {
                let temp = this.promotedPromotions
                    .concat(this.activePromotions)
                    .concat(this.inactivePromotions);

                try {
                    await Promise.all(
                        temp.map(
                            async ({ _id, order }, ind) => {
                                if (order !== ind) {
                                    return this.$store.dispatch(
                                        "promotion/edit",
                                        { _id, "order": ind, }
                                    );
                                }
                                return undefined;
                            }
                        )
                    );
                } catch (e) {
                    console.error(e);
                }
            }
        },
    },
    mounted () {
        if (
            Array.isArray(this.promotions) &&
            this.promotions.length > 0
        ) {
            this.activePromotions = this.promotions
                .filter(
                    ({ active, promoted }) => active && !promoted
                );
            this.promotedPromotions = this.promotions
                .filter(
                    ({ active, promoted }) => active && promoted
                );
            this.inactivePromotions = this.promotions
                .filter(
                    ({ active }) => !active
                );
        }
    },
    watch: {
        "promotions" (newVal) {
            if (
                Array.isArray(newVal) &&
                newVal.length > 0
            ) {
                this.activePromotions = newVal
                    .filter(
                        ({ active, promoted }) => active && !promoted
                    );
                this.promotedPromotions = newVal
                    .filter(
                        ({ active, promoted }) => active && promoted
                    );
                this.inactivePromotions = newVal
                    .filter(
                        ({ active }) => !active
                    );
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.promotions {
    .title {
        top: 0;
        background-color: rgba(255, 255, 255, 1);
        z-index: 2;
    }

    .promotion-list-heading {
        top: 67px;
        background-color: rgba(255, 255, 255, 1);
        z-index: 1;
    }
}
</style>
